import React from 'react'

import { Heading, Text } from 'src/components/system'
import { Layout } from 'src/components/Layout'

const NotFoundPage = () => (
  <Layout>
    <Heading>Not Found</Heading>
    <Text>Implement 404 page here</Text>
  </Layout>
)

export default NotFoundPage
